import React, { useEffect, useRef, useState } from 'react';
import { expandAll } from 'assets';
import './AutoGroupHeader/AutoGroupHeader.css';
import { clearFiltersIcon } from 'assets';

/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
const ColumnWithClearFilter = (params) => {
  const {
    api,
    column,
    displayName,
    enableMenu,
    enableSorting,
    showColumnMenu,
    setSort,
  } = params;
  const [ascSort, setAscSort] = useState('');
  const [descSort, setDescSort] = useState('ag-hidden');
  const [noSort, setNoSort] = useState('ag-hidden');
  const [showClearFilters, setShowClearFilters] = useState(false);
  const menuButtonRef = useRef(null);

  const onMenuClicked = (e) => {
    e.stopPropagation();
    showColumnMenu(menuButtonRef.current);
  };

  const renderMenuButton = () => {
    // early return if enableMenu is disabled in grid options
    if (!enableMenu) return null;

    return (
      <span
        ref={menuButtonRef}
        onClick={onMenuClicked}
        onTouchEnd={onMenuClicked}
        data-ref="eMenu"
        className="ag-header-icon ag-header-cell-menu-button"
        aria-hidden="true"
        style={{ marginRight: showClearFilters ? '10px' : 0 }}
      >
        <span className="ag-icon ag-icon-menu" />
      </span>
    );
  };

  const onSortChanged = () => {
    const sort = column.getSort();
    setAscSort(sort === 'asc' ? '' : 'ag-hidden');
    setDescSort(sort === 'desc' ? '' : 'ag-hidden');
    setNoSort(!sort ? '' : 'ag-hidden');
  };

  const onSortRequested = (event) => {
    const currentSort = column.getSort();

    switch (currentSort) {
      case 'asc':
        setSort('desc', event.shiftKey);
        break;
      case 'desc':
        setSort('', event.shiftKey);
        break;
      default:
        setSort('asc', event.shiftKey);
        break;
    }
  };

  const renderSortIconContainer = () => {
    // early return if enableSorting is disabled in grid options
    if (!enableSorting) return null;

    return (
      <span className="ag-sort-indicator-container">
        <span data-ref="eSortOrder" className="ag-sort-indicator-icon ag-sort-order ag-hidden" aria-hidden="true" />
        <span data-ref="eSortAsc" className={`ag-sort-indicator-icon ag-sort-ascending-icon ${ascSort}`} aria-hidden="true">
          <span className="ag-icon ag-icon-asc" />
        </span>
        <span data-ref="eSortDesc" className={`ag-sort-indicator-icon ag-sort-descending-icon ${descSort}`} aria-hidden="true">
          <span className="ag-icon ag-icon-desc" />
        </span>
        <span data-ref="eSortMixed" className="ag-sort-indicator-icon ag-sort-mixed-icon ag-hidden" aria-hidden="true" />
        <span data-ref="eSortNone" className={`ag-sort-indicator-icon ag-sort-none-icon ag-hidden ${noSort}`} aria-hidden="true">
          <span className="ag-icon ag-icon-none" />
        </span>
      </span>
    );
  };

  useEffect(() => {
    const onFilterChange = () => {
      setShowClearFilters(Object.keys(api.getFilterModel()).length > 0);
    };

    /**
    * If a column overflows beyond the parent container in the Data Grid, it will be removed from rendering.
    * This unmounting causes the template to lose track of its previous state. As a result, when the column
    * with the clearFilter template is scrolled back into view, both the sort and filter states will be lost,
    * reverting to their default values, even if the grid is currently sorted or filtered.
    * We call these functions on mount to verify if the table is sorted or filtered.
    */
    onSortChanged();
    onFilterChange();

    // event listener to track changes in column sorting
    column.addEventListener('sortChanged', onSortChanged);

    // event listener to track filter changes
    api.addEventListener('filterChanged', onFilterChange);

    // cleanup event listeners to avoid memory leaks
    return () => {
      column.removeEventListener('sortChanged', onSortChanged);
      api.removeEventListener('filterChanged', onFilterChange);
    };
  }, []);

  /*
   * Note: Elements need to be positioned in reverse as Ag-Grid styling applies row-reverse
   * on ag-cell-label-container.
  */
  return (
    <div
      className="ag-cell-label-container ag-header-cell-sorted-asc op2mise-auto-group-header"
      role="presentation"
    >
      {showClearFilters && (
        <div
          className="ag-grid-header-clear-filters"
          style={{ marginTop: '-5px', cursor: 'pointer' }}
          onClick={() => api.setFilterModel(null)}
        >
          <img onMouseEnter={(e) => e.stopPropagation()} src={clearFiltersIcon} alt="clear-filters" title="Clear all filters" />
        </div>
      )}
      {renderMenuButton()}
      <div
        data-ref="eLabel"
        className="ag-header-cell-label"
        role="presentation"
        onClick={(event) => onSortRequested(event)}
      >
        <span data-ref="eText" className="ag-header-cell-text">{displayName}</span>
        {renderSortIconContainer()}
      </div>
    </div>
  );
};

export default ColumnWithClearFilter;
