import React from 'react';
import { Modal } from 'react-bootstrap';
import Button from 'shared/components/button/Button';

const NotifyStationModal = (props) => {
  const {
    content,
    onClose,
    onProceed,
    isActionPending,
    show,
  } = props;

  return (
    <Modal
      centered
      dialogClassName="campaign-station-notification-modal"
      show={show}
    >
      <Modal.Body>
        <div className="campaign-station-notification-modal-content">
          {content}
          <div className="text-center">
            <Button text="No" onClick={onClose} defaultBtn />
            <Button
              text="Yes"
              // style={{ width: '100px' }}
              onClick={onProceed}
              isLoading={isActionPending}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default NotifyStationModal;