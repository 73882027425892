/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
import {
  buildingIcon,
  hornIcon,
  settingsIcon,
  userIcon,
  scheduleIcon,
  availIcon,
  networkIcon,
  pinIcon,
  cablesIcon,
  globeIcon,
  atomsIcon,
  filmTicketIcon,
  envelopeWhiteIcon,
  programme,
  cableIcon,
  demographicsIcon,
  contactIcon,
  creativesIcon,
  traffickingIcon,
  instructionsIcon,
  orderManagementIcon,
  clientsIcon,
} from '../../assets';

const superAdminNavigations = [
  {
    id: 1,
    title: 'Organizations',
    path: '/organisations',
    icon: buildingIcon,
  },
  {
    id: 2,
    title: 'Users',
    path: '/users',
    icon: userIcon,
  },
  {
    id: 3,
    title: 'Settings',
    collapsible: true,
    icon: settingsIcon,
    subLinks: [
      {
        id: 1,
        subTitle: 'Email Triggers',
        path: '/email-trigger',
        icon: envelopeWhiteIcon,
      },
      {
        id: 2,
        subTitle: 'Markets',
        path: '/data-management/markets',
        icon: pinIcon,
      },
      {
        id: 3,
        subTitle: 'Stations/Cables',
        path: '/data-management/stations',
        icon: cableIcon,
      },
    ]
  },
];

const organisationalAdminNavigations = [
  {
    id: 1,
    title: 'Campaigns',
    path: '/campaigns',
    icon: hornIcon,
  },
  {
    id: 2,
    title: 'Orders',
    path: '/orders',
    icon: orderManagementIcon,
  },
  {
    id: 3,
    title: 'Contact Management',
    path: '/contacts',
    icon: contactIcon,
  },
  {
    id: 4,
    title: 'Trafficking',
    collapsible: true,
    icon: traffickingIcon,
    subLinks: [
      {
        id: 1,
        subTitle: 'Creatives',
        path: '/trafficking/creatives',
        icon: creativesIcon,
      },
      {
        id: 2,
        subTitle: 'Instructions',
        path: '/trafficking/instructions',
        icon: instructionsIcon,
      },
    ]
  },
  {
    id: 5,
    title: 'Program Filters',
    path: '/programme',
    icon: programme,
  },
  {
    id: 6,
    title: 'Settings',
    collapsible: true,
    icon: settingsIcon,
    subLinks: [
      {
        id: 1,
        subTitle: 'Users',
        path: '/users',
        icon: userIcon,
      },
      {
        id: 2,
        subTitle: 'Clients',
        path: '/data-management/clients',
        icon: clientsIcon,
      },
      {
        id: 3,
        subTitle: 'Markets',
        path: '/data-management/markets',
        icon: pinIcon,
      },
      {
        id: 4,
        subTitle: 'Stations/Cables',
        path: '/data-management/stations',
        icon: cableIcon,
      },
      {
        id: 5,
        subTitle: 'Demographics',
        path: '/data-management/demographics',
        icon: demographicsIcon,
      },
    ]
  },
];

const organisationalUserNavigations = [
  {
    id: 1,
    title: 'Campaigns',
    path: '/campaigns',
    icon: hornIcon,
  },
  {
    id: 2,
    title: 'Orders',
    path: '/orders',
    icon: orderManagementIcon,
  },
  {
    id: 3,
    title: 'Contact Management',
    path: '/contacts',
    icon: contactIcon,
  },
  {
    id: 4,
    title: 'Trafficking',
    collapsible: true,
    icon: traffickingIcon,
    subLinks: [
      {
        id: 1,
        subTitle: 'Creatives',
        path: '/trafficking/creatives',
        icon: creativesIcon,
      },
      {
        id: 2,
        subTitle: 'Instructions',
        path: '/trafficking/instructions',
        icon: instructionsIcon,
      },
    ]
  },
  {
    id: 5,
    title: 'Programme Filters',
    path: '/programme',
    icon: programme,
  },
];

export {
  superAdminNavigations,
  organisationalAdminNavigations,
  organisationalUserNavigations,
};